<template>
  <v-container fluid>
    <v-card elevation="0">
      <v-card-title>대행사 정산</v-card-title>
      <v-row no-gutters class="px-5">
        <v-col cols="12" lg="3" sm="12">
          <v-autocomplete
            v-model="user_id"
            :items="agency_items"
            label="대행사"
            item-text="name"
            item-value="id"
            placeholder="대행사를 선택하세요."
            no-data-text="존재하지 않는 대행사입니다."
            hide-details
            @change="getData"
          ></v-autocomplete>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" lg="1" sm="12">
          <v-menu
            v-model="startMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="100%"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatStartDate"
                readonly
                v-bind="attrs"
                v-on="on"
                label="시작 날짜"
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              ref="startDate"
              @input="startMenu = false"
              :max="startMaxDate"
              locale="ko-KR"
              no-title
              reactive
            ></v-date-picker>
          </v-menu>
        </v-col>
        <p class="text-center mx-auto px-5 mt-3">~</p>
        <v-col cols="12" lg="1" sm="12" class="mr-5">
          <v-menu
            v-model="endMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="100%"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatEndDate"
                readonly
                v-bind="attrs"
                v-on="on"
                label="종료 날짜"
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              ref="endDate"
              @input="endMenu = false"
              :min="startDate"
              :max="endMaxDate"
              locale="ko-KR"
              no-title
              reactive
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-btn color="primary" @click="getData()" class="mx-auto mt-3"
          >검색</v-btn
        >
      </v-row>
      <v-row>
        <v-col class="px-7">
          <v-progress-circular
            v-if="loading"
            :indeterminate="loading"
            class="progress_circular"
          ></v-progress-circular>
          <div
            v-if="chart"
            :style="`height:${chartHeight}px;`"
            class="chart_wrap"
          >
            <div ref="chart" style="height: 100%"></div>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-row class="mt-5">
      <v-col>
        <v-data-table
          :headers="headers"
          :items="tableItems"
          :expanded.sync="expanded"
          item-key="date"
          loading-text="로딩중.. 기다려주세요."
          no-data-text="데이터가 없습니다."
          single-expand
          show-expand
          elevation="0"
          hide-default-footer
        >
          <template v-slot:[`item.date`]="{ item }">
            {{ getMonth(item.date) }}
          </template>
          <template v-slot:[`item.survey`]="{ item }">
            {{ convertComma(item.survey) }}
          </template>
          <template v-slot:[`item.settlement`]="{ item }">
            {{ convertComma(item.settlement) }}
          </template>
          <template v-slot:[`item.state`]="{ item }">
            <v-select
              v-model="item.state"
              :items="state_items"
              item-text="name"
              item-value="state"
              @change="changeState(item)"
              style="max-width: 240px; margin: 0 auto"
            ></v-select>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <template v-if="item.history.length > 0">
              <td :colspan="headers.length" class="pa-0">
                <v-data-table
                  :headers="expandedHeaders"
                  :items="item.history"
                  hide-default-footer
                  mobile-breakpoint="0"
                  no-data-text="데이터가 없습니다."
                  style="background: rgba(0, 0, 0, 0.03)"
                ></v-data-table>
              </td>
            </template>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as echarts from "echarts";
import moment from "moment";

export default {
  name: "AgencyCalculate",
  data() {
    return {
      expanded: [],
      state_items: [
        {
          name: "대기중",
          state: "waiting",
        },
        {
          name: "확정",
          state: "confirm",
        },
        {
          name: "임금 완료",
          state: "settled",
        },
        {
          name: "세금계산서 발행",
          state: "tax",
        },
      ],
      headers: [
        {
          text: "날짜",
          value: "date",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
          align: "center",
        },
        {
          text: "담당자",
          value: "contact_name",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
          align: "center",
        },
        {
          text: "응답 수",
          value: "survey",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
          align: "center",
        },
        {
          text: "수수료",
          value: "settlement",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
          align: "center",
        },
        {
          text: "상태",
          value: "state",
          sortable: false,
          filterable: false,
          class: "font-weight-black subtitle-2",
          align: "center",
        },
        {
          text: "",
          value: "data-table-expand",
        },
      ],
      expandedHeaders: [
        {
          text: "날짜",
          value: "date",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
          align: "center",
        },
        {
          text: "상태",
          value: "current",
          filterable: false,
          sortable: false,
          class: "font-weight-black subtitle-2",
          align: "center",
        },
      ],
      tableItems: [],
      dates: [],
      settlement: [],

      user_id: 0,
      agency_items: [],

      // 차트옵션 공통 값
      colorPalette: ["#599BFF"],
      chartHeight: 0,
      chart: false,
      loading: false,

      today: moment().format("YYYY-MM-DD"),
      startDate: null,
      startMenu: false,
      endDate: null,
      endMenu: false,
      startMaxDate: moment(this.endDate).format("YYYY-MM") + "-NaN",
      endMaxDate: moment(this.today).format("YYYY-MM") + "-NaN",
      formatStartDate: null,
      formatEndDate: null,
    };
  },
  mounted() {
    this.startDate = moment(this.today)
      .subtract(2, "M")
      .startOf("month")
      .format("YYYY-MM-DD");
    this.endDate = moment(this.today).format("YYYY-MM-DD");
    this.formatStartDate = moment(this.startDate).format("YYYY-MM");
    this.formatEndDate = moment(this.endDate).format("YYYY-MM");
    this.getAgency();
  },
  watch: {
    // 달력 타입에 따라 날짜 포맷 다르게 변경(invaild value 방지)
    startDate(v) {
      v = v.substr(0, 7);
      this.formatStartDate = v;
    },
    endDate(v) {
      v = v.substr(0, 7);
      this.formatEndDate = v;
      if (
        moment(this.endDate).format("YYYY-MM") ===
        moment(this.today).format("YYYY-MM")
      ) {
        this.endDate = moment(this.today).format("YYYY-MM-DD");
      } else {
        this.endDate = moment(this.endDate).endOf("month").format("YYYY-MM-DD");
      }
    },

    // 달력 타입 변경(라이브러리에서 제공되지 않아서 강제변경)
    startMenu(v) {
      v &&
        this.$nextTick(
          () => (this.$refs.startDate.internalActivePicker = "MONTH")
        );
    },
    endMenu(v) {
      v &&
        this.$nextTick(
          () => (this.$refs.endDate.internalActivePicker = "MONTH")
        );
    },
  },
  methods: {
    changeState(item) {
      let data = {
        date: item.date,
        state: item.state,
        user_id: this.user_id,
      };
      this.axios
        .patch(`api/v1/manage/agent/settlements/updateState`, data)
        .then(() => {
          this.getAgency();
          this.$store.commit("alert/showAlert", {
            message: `상태 변경이 완료되었습니다.`,
          });
        });
    },

    convertComma(value) {
      if (value !== undefined) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },

    getMonth(date) {
      const nowDate = moment(date).utc(true);
      const final = nowDate.format("YYYY년 MM") + "월";
      return final;
    },

    resetChartField() {
      this.tableItems = [];
      this.dates = [];
      this.surveys = [];
      this.settlement = [];
    },

    async getAgency() {
      let agency = [];
      await this.axios.get("api/v1/manage/agent").then((res) => {
        if (res.data.agents.data.length > 0) {
          res.data.agents.data.map((item) => {
            agency.push({
              id: item.user_id,
              name: item.name,
            });
          });
        }
        this.agency_items = agency;
        this.user_id = this.agency_items[0].id;
      });
      this.getData();
    },

    async getData() {
      this.loading = true;
      this.resetChartField();

      let items = [];
      let date = [];
      let settlement = [];
      let params = {
        user_id: this.user_id,
        start_at: this.startDate,
        end_at: this.endDate,
      };
      await this.axios
        .get(`api/v1/manage/agent/settlements`, { params })
        .then((res) => {
          if (res.data.states.length > 0) {
            this.chart = true;
            this.chartHeight = 350;

            res.data.states.map((el) => {
              date.push(el.date);
              settlement.push(el.fee);
              let history = el.history.map((el2) => {
                if (el2.current === "waiting") {
                  el2.current = "대기중";
                  return el2;
                } else if (el2.current === "confirm") {
                  el2.current = "확정";
                  return el2;
                } else if (el2.current === "settled") {
                  el2.current = "임금완료";
                  return el2;
                } else if (el2.current === "tax") {
                  el2.current = "세금계산서 발행";
                  return el2;
                }
              });
              items.push({
                date: el.date,
                survey: el.cnt,
                settlement: el.fee,
                active: false,
                state: el.state,
                history: history,
                contact_name: el.contact_name,
              });
            });
          }
          this.tableItems = items;
          this.dates = date.reverse();
          this.settlement = settlement.reverse();
        })
        .catch(() => {
          this.loading = false;
          this.chart = false;
        });

      if (this.chart) {
        await this.printChart();
      }
      this.loading = false;
    },

    async printChart() {
      this.$nextTick(() => {
        const chartDom = this.$refs.chart;
        let myChart = echarts.init(chartDom);

        myChart.setOption({
          color: this.colorPalette,
          tooltip: {
            trigger: "axis",
            formatter: (params) => {
              let item = "";
              item += `<div style="display:flex;justify-content:space-between;text-align:left"><span style="margin-right:10px">${
                params[0].marker
              }${
                params[0].seriesName
              }</span><span style="font-weight:600">${this.convertComma(
                params[0].value
              )}</span></div>`;

              let intervalValue = params[0].name;
              return `<p style="margin-bottom: 10px;text-align:left">${this.getMonth(
                intervalValue
              )}</p> ${item}`;
            },
          },
          legend: {
            data: ["수수료"],
            orient: "horizontal",
            top: "bottom",
          },
          grid: {
            left: "0%",
            top: "5%",
            right: "20px",
            bottom: "15%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            data: this.dates,
            axisTick: { show: false },
            axisLabel: {
              formatter: (value) => {
                return this.getMonth(value);
              },
            },
          },
          yAxis: [
            {
              type: "value",
            },
          ],
          series: [
            {
              name: "수수료",
              type: "bar",
              data: this.settlement,
              barWidth: "20px",
              emphasis: {
                focus: "series",
              },
            },
            {
              name: "수수료",
              type: "line",
              data: this.settlement,
              color: "#dd4343",
              emphasis: {
                focus: "series",
              },
            },
          ],
        });
        window.addEventListener("resize", myChart.resize);
      });
    },
  },
};
</script>
<style scoped>
.v-application p {
  margin: 0;
  padding: 8px 0;
}
.progress_circular {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
